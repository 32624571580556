import React, { useContext } from "react";
import logoSrc from "../../../../static/2020/svg/logo_2.svg";
import { DataContext } from "../../../contexts/DataContext";
import Link from "../../Link/Link";
import { StyledHeader, StyledHeaderLogo, StyledLangFlag } from "../style";

const Header = () => {
  const { languages, i18nLink } = useContext(DataContext);
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const flag = currentLanguageCode === "it" ? "en" : "it";
  const url = currentLanguageCode === "it" ? "/en/thegreatmeltdown" : "/thegreatmeltdown";
  
  return (
    <StyledHeader>
      <StyledHeaderLogo href={i18nLink("/")}>
        <img src={logoSrc} loading="lazy" />
      </StyledHeaderLogo>
      <Link to={url}>
        <StyledLangFlag
          bg={`/2020/images/flags/${flag}.png`}
        />
      </Link>
    </StyledHeader>
  );
};

export default Header;
